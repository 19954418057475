import {Component, forwardRef, Inject, Input} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {AbstractCustomInputComponent} from "../abstract-custom-input/abstract-custom-input.component";
import {NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
    selector: 'app-range-input',
    templateUrl: './range-input.component.html',
    styleUrls: ['./range-input.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RangeInputComponent),
            multi: true,
        },
    ]
})
export class RangeInputComponent extends AbstractCustomInputComponent {
    @Input() min: number;
    @Input() max: number;
    @Input() initialValue: number;
    @Input() labelStart: string;
    @Input() labelEnd: string;

    constructor(@Inject(DOCUMENT) document: Document) {
        super(document);
    }
}

