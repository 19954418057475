<div class="form-element w-45">
  <label for="lat">Latitude</label>
  <input class="w-100" type="number" name="lat" id="lat"
         [(ngModel)]="lat"
         (ngModelChange)="changeLocation(lat, lon)">
</div>
<div class="form-distance w-10"></div>
<div class="form-element w-45">
  <label for="lon">Longitude</label>
  <input class="w-100" type="number" name="lon" id="lon"
         [(ngModel)]="lon"
         (ngModelChange)="changeLocation(lat, lon)">
</div>
<div class="row" #map>
  <div id="mapInputPlaceholder"></div>
</div>
