import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'apastropheThousand'
})
export class ApastropheThousandPipe implements PipeTransform {

    transform(value: number, customPrefix: boolean): string {
        let prefix = '';
        if (customPrefix) {
            prefix = '+ ';

            if (value < 0) {
                prefix = '- ';
                value = Math.abs(value);
            }
        }

        if (!value) return '-';

        return prefix + value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, "'");
    }

}
