import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit, OnChanges {
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  @Input() pagesCountInput;
  @Input() resourcesCountInput;
  @Input() setPage;
  @Input() disabled;
  pagesCount: number = 1;
  currentPage: number = 1;
  @Output() pageSelect = new EventEmitter<number>();
  @Output() pageSizeChange = new EventEmitter<number>();
  pageSize = 10;
  resourcesCount = 0;

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.pagesCountInput) {
      this.pagesCount = this.pagesCountInput;
    }
    if (this.resourcesCountInput !== undefined) {
      this.resourcesCount = this.resourcesCountInput;
      if (this.resourcesCount <= 5) {
        this.pageSize = this.resourcesCount;
      }
    }
    if (this.setPage) {
      this.currentPage = this.setPage;
    }
  }

  changePage(newPage) {
    this.currentPage = newPage;
    this.pageSelect.emit(this.currentPage);
  }

  pageSizeChangeEmit() {
    this.pageSizeChange.emit(this.pageSize);
  }
}
