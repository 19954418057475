import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SnackbarComponent} from './snackbar.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';


@NgModule({
    declarations: [SnackbarComponent],
    imports: [
        FontAwesomeModule,
        CommonModule
    ],
    exports: [SnackbarComponent]
})
export class SnackbarModule {
}
