<div #baseDialog class="dialog-container">
  <div id="dialog" class="dialog" *ngIf="config">
    <div class="dialog-content">
      <!-- DIALOG HEADER -->
      <div class="header">
        <h1 style="text-transform: capitalize;">{{config.title}}</h1>
        <fa-icon class="close-icon"
                 [icon]="closeIcon"
                 [size]="'sm'"
                 style="position: absolute; right: 16px; top: 16px; color: #B0B7C3;"
                 (click)="closeDialog($event, true)"></fa-icon>
      </div>
      <!-- DIALOG HEADER END -->
      <!-- DIALOG MAIN CONTENT -->
      <main>
        {{config.text}}
      </main>
      <!-- DIALOG MAIN CONTENT END -->
      <!-- DIALOG FOOTER -->
      <div class="cf footer">
        <button class="btn btn-md"
                [ngClass]="button.class"
                *ngFor="let button of config.buttons"
                (click)="buttonClickedEmit(button.name)">
          {{button.label || button.name}}
        </button>

      </div>
      <!-- DIALOG FOOTER END -->
    </div>
    <div class="overlay" (click)="closeDialog($event, false)"></div>
  </div>
</div>
