import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DragAndDropDirective} from './directives/drag-and-drop.directive';
import {ApastropheThousandPipe} from './pipes/apastrophe-thousand.pipe';

@NgModule({
    declarations: [DragAndDropDirective, ApastropheThousandPipe],
    imports: [
        CommonModule
    ],
    exports: [DragAndDropDirective, ApastropheThousandPipe]
})
export class SharedModule {
}
