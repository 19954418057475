import {Component, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {faFile, faPlus, faTimes} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-file-input',
    templateUrl: './file-input.component.html',
    styleUrls: ['./file-input.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileInputComponent),
            multi: true,
        },
    ],
})
export class FileInputComponent implements OnInit, ControlValueAccessor {
    @Input() placeholder: string;
    @Input() disabled: boolean;
    @Input() fileTypes: string[];
    @Input() multiple = true;
    closeIcon = faTimes;
    fileIcon = faFile;

    onChange: any = () => {
    };
    onTouch: any = () => {
    };

    val = ''; // value of the component
    filenames = [];

    @ViewChild('fileInput', {static: false}) fileInput;


    constructor() {
    }

    ngOnInit(): void {
    }

    set value(val) {
        this.val = val;
        this.onChange(val);
        this.onTouch(val);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(value: any): void {
        this.value = value;

        if (!value) {
            delete this.filenames;
        }
    }

    uploadFile(files: any) {

        if (this.multiple) {
            // TODO: FileType check

            if (this.val) {
                this.value = Array.from(this.val).concat(Array.from(files));
                this.filenames = this.filenames.concat(Array.from(files).map(file => file['name']));
            } else {
                this.value = files;
                this.filenames = Array.from(files).map(file => file['name']);
            }
        } else {
            const fileType = files[0].type || files[0].name.split('.').length > 0 ? files[0].name.split('.').slice(-1)[0] : '';

            if (this.fileTypes) {
                if (this.fileTypes.includes(fileType)) {
                    this.value = files[0];
                    this.filenames = [files[0].name];
                }
            }
        }
    }

    deleteFile() {
        this.value = '';
        this.fileInput.nativeElement.value = '';
        this.filenames = [];
    }
}
