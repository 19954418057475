import {Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import Lightpick from 'lightpick';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
    selector: 'app-date-range',
    templateUrl: './date-range.component.html',
    styleUrls: ['./date-range.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateRangeComponent),
            multi: true,
        },
    ],
})
export class DateRangeComponent implements OnInit, ControlValueAccessor {
    onChange: any = () => {
    };
    onTouch: any = () => {
    };
    val = {date_time__gte: '', date_time__lte: ''}; // value of the component

    @Input() placeholder: string;
    @Input() singleDate: boolean;
    @Input() readOnly: boolean;
    picker: Lightpick;

    constructor() {
    }

    ngOnInit(): void {
        let self = this;

        this.picker = new Lightpick({
            field: document.getElementById('datepicker'),
            singleDate: this.singleDate || false,
            footer: true,
            selectForward: true,
            onSelect: function (start, end) {
                if (self.singleDate) {
                    self.value = start.format();
                } else if (start && end) {
                    self.value = {date_time__gte: start.format(), date_time__lte: end.format()};
                }
            }
        });

        if (this.readOnly) {
            this.picker.destroy();
        }
    }

    set value(val) {
        this.val = val;
        this.onChange(val);
        this.onTouch(val);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(value: any): void {
        this.value = value;

        this.picker.setDate(this.val);
    }

}
