import {Component, ElementRef, forwardRef, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DOCUMENT} from '@angular/common';

@Component({
    selector: 'app-abstract-custom-input',
    templateUrl: './abstract-custom-input.component.html',
    styleUrls: ['./abstract-custom-input.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AbstractCustomInputComponent),
            multi: true,
        },
    ]
})
export class AbstractCustomInputComponent implements OnInit, ControlValueAccessor {
    @Input() options: any[];
    @Input() placeholder: string;
    @Input() readonly: boolean;
    @ViewChild('input', {static: true, read: ElementRef}) inputElementRef: ElementRef;

    val = '';

    constructor(@Inject(DOCUMENT) private document: Document) {
    }

    onChange: any = () => {
    };

    onTouch: any = () => {
    };

    ngOnInit(): void {
    }

    set value(val) {
        this.val = val;
        this.onChange(val);
        this.onTouch(val);
    }

    get value(): any {
        return this.val;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(value: any): void {
        this.value = value;
    }
}
