<div class="tabs-container">
  <div class="tabs">
    <div class="tab"
         *ngFor="let tab of tabs"
         (click)="selectTab(tab)"
         [ngClass]="{'active': selectedTab.name === tab.name}">
      {{tab.title}}
    </div>
  </div>
</div>
