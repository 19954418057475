<div class="no-sites">
    <img src="../../../../../../../assets/images/empty-state.png" alt="No sites">
    <h1>
        {{config.heading}}
    </h1>
    <p>
        {{config.subheading}}
    </p>
    <button class="btn btn-action" (click)="createNew()">
        {{config.button}}
    </button>
</div>
