<div class="card" [ngClass]="{'active': config.active, 'needsAction': resource?.status?.label === 'Validation required'}"
     [style.background-color]="resource.backgroundColor ? resource.backgroundColor : '' ">
    <div class="card-header">
        <div class="card-img-container" *ngIf="resource.image">
            <img class="card-img" [src]="resource.image" (error)="setPlaceholderImg()" alt="project image">
        </div>
        <div style="text-align: right;">
            <ng-container *ngIf="resource.status">
                <div [style.background-color]="resource.status.color" class="status-label">
                    {{resource.status.label}}
                </div>
            </ng-container>
            <ng-container *ngIf="config && config.menuButtons">

            </ng-container>
            <app-dropdown-menu style="text-align: left;"
                               [style.display]="config.menuButtons.length > 0 ? 'initial' : 'none'"
                               (click)="$event.stopPropagation()"
                               [buttons]="config.menuButtons"
                               (buttonClick)="menuClickHandle($event)">
            </app-dropdown-menu>
        </div>
    </div>
    <div class="card-content">
        <p class="card-title">
            {{resource.name}}
        </p>
        <p class="card-subtitle">
            {{resource.description}}
        </p>
    </div>
    <div class="card-footer">
        <ng-content select="[footer]"></ng-content>
    </div>
    <div class="tool-tooltip" *ngIf="resource.tooltip">
        <div class="arrow"></div>
        <div class="tooltip-content">
            {{resource.tooltip}}
        </div>
    </div>
</div>
