import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

class ListEmptyConfig {
    heading: string;
    subheading: string;
    button: string;
}

@Component({
    selector: 'app-list-empty',
    templateUrl: './list-empty.component.html',
    styleUrls: ['./list-empty.component.css']
})
export class ListEmptyComponent implements OnInit {

    @Input() config: ListEmptyConfig;
    @Output() createNewClicked = new EventEmitter<boolean>();

    constructor() {
    }

    ngOnInit(): void {
    }

    createNew() {
        this.createNewClicked.emit(true);
    }
}
