import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {faTimes} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.css']
})
export class RightSidebarComponent implements OnInit {
  closeIcon = faTimes;
  data: any;
  @ViewChild('rightSidebar', {static: false}) rightSidebar;

  @Output() closed = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit(): void {
  }

  openSidebar() {
    this.rightSidebar.nativeElement.classList.add('active');
  }
  loadDataAndOpen(data: any) {
    this.data = data;
    this.openSidebar();
  }
  closeSidebar(event, force) {
    if (force) {
      this.rightSidebar.nativeElement.classList.remove('active');
      this.closed.emit(true);
    } else if (!event.target.closest('.right-sidebar-content') &&
      !event.target.closest('.btn')) {
      this.rightSidebar.nativeElement.classList.remove('active');
      this.closed.emit(true);
    }
  }
}
