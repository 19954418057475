import {Component, forwardRef, Inject, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {
    AbstractCustomInputComponent
} from '@app/modules/custom-c-d-k/modules/resource/inputs/abstract-custom-input/abstract-custom-input.component';
import {DOCUMENT} from '@angular/common';


@Component({
    selector: 'app-switch-input',
    templateUrl: './switch-input.component.html',
    styleUrls: ['./switch-input.component.css'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => SwitchInputComponent),
        multi: true,
    }]
})
export class SwitchInputComponent extends AbstractCustomInputComponent {
    constructor(@Inject(DOCUMENT) document: Document) {
        super(document);
    }
}

