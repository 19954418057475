import {NgModule, ModuleWithProviders, APP_INITIALIZER} from '@angular/core';
import {AuthInterceptor} from './auth.interceptor';
import {AuthService} from './auth.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthGuard, NonAuthGuard} from './auth.guard';
import {AppConfig, initializeApp} from "../config/app-config";

@NgModule({
  imports: [],
  declarations: [],
  exports: []
})
export class AuthModule {
  static forRoot(config): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        AuthService,
        {provide: 'appConfig', useValue: config},
        AuthGuard,
        NonAuthGuard,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true
        },
      ]
    };
  }
}
