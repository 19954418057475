import {Component, Input, OnInit, EventEmitter, Output, SimpleChanges} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {AbstractRestService} from '../../../../../../data/services/resource/abstract-rest.service';

export enum FieldType {
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  BOOLEAN = 'BOOLEAN',
  SWITCH = 'SWITCH',
  DATE = 'DATE',
  DATERANGE = 'DATERANGE',
  FILE = 'FILE',
  INTEGER = 'INTEGER',
  LOCATION = 'LOCATION',
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
  PASSWORD = 'PASSWORD',
  TEXTAREA = 'TEXTAREA',
  DATETIME = 'DATETIME',
  SELECT = 'SELECT',
  IMAGE = 'IMAGE'
}

export class ResourceFormConfig {
  fields: {
    control: UntypedFormControl,
    name: string,
    label?: string,
    type?: string,
    class?: string,
    readonly?: boolean;
    maxLength?: number,
    options?: any[],
    autocomplete?: boolean,
    placeholder?: string;
    fileTypes?: string[];
    disabled?: boolean;
  }[];
}

export function getResourceFormControl(resourceFormConfig: ResourceFormConfig, name: string): UntypedFormControl | null {
  return resourceFormConfig.fields.find(f => {
    return (f.name === name);
  })?.control;
}

export function getResourceFormOptions(resourceFormConfig: ResourceFormConfig, name: string): any[] | null {
  return resourceFormConfig.fields.find(f => {
    return (f.name === name);
  })?.options;
}

export function setResourceFormConfigField(resourceFormConfig: ResourceFormConfig, name: string, property: string, value: any): boolean {
  let fieldSet = false;
  resourceFormConfig.fields.forEach(field => {
    if (field.name === name) {
      field[property] = value;
      fieldSet = true;
    }
  });

  return fieldSet;
}
export function setResourceFormControlValue(resourceFormConfig: ResourceFormConfig, name: string, value: any): boolean {
  let fieldSet = false;
  resourceFormConfig.fields.forEach(field => {
    if (field.name === name) {
      field.control.setValue(value);
      fieldSet = true;
    }
  });

  return fieldSet;
}

export async function getOptionsFromAPI(service: AbstractRestService<any, any>, valueKey?, nameKey?, methodName?):
  Promise<{ value: string, name: string }[]> {
  let response;
  if (methodName) {
    response = await service[methodName]().toPromise();
  } else {
    response = await service.getAll().toPromise();
  }
  let options = [];
  if (response.results) {
    options = response.results.map(option => {
      return {value: valueKey ? option[valueKey] : option.id, name: nameKey ? option[nameKey] : option.name};
    });
  } else {
    options = response.map(option => {
      return {value: valueKey ? option[valueKey] : option.id, name: nameKey ? option[nameKey] : option.name};
    });
  }

  return options;
}

@Component({
  selector: 'app-resource-form',
  templateUrl: './resource-form.component.html',
  styleUrls: ['./resource-form.component.css']
})
export class ResourceFormComponent implements OnInit {
  resetFormSubject = new Subject<boolean>();

  @Input() config: ResourceFormConfig;
  @Output() valueChanges = new EventEmitter<UntypedFormGroup>();

  resourceFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  FieldType = FieldType;

  constructor() {
    this.resourceFormGroup.valueChanges.subscribe(val => {
      this.valueChanges.emit(this.resourceFormGroup);
    });

    this.resetFormSubject.asObservable().subscribe(ev => {
      let fields = {};

      this.config.fields.map(field => {
        fields[field.name] = '';
      });

      this.resourceFormGroup.patchValue(fields);
      this.resourceFormGroup.reset();
      this.resourceFormGroup.markAsPristine();

    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config) {
      if (this.config && this.config.fields) {
        this.config.fields.map(field => {
          this.resourceFormGroup.registerControl(field.name, field.control);
        });

        this.valueChanges.emit(this.resourceFormGroup);
      }

    }
  }

  ngOnInit(): void {
  }

  getResetFormSubject(): Subject<boolean> {
    return this.resetFormSubject;
  }

  getFormGroup() {
    return this.resourceFormGroup;
  }

  setFormControlError(name: string, error: string) {
    this.resourceFormGroup.controls[name].setErrors({error: true});
  }
}
