import {Component} from '@angular/core';

@Component({
  selector: 'app-inline-spinner',
  templateUrl: './inline-spinner.component.html',
  styleUrls: ['./inline-spinner.component.css']
})
export class InlineSpinnerComponent {

  constructor() {
  }

}
