import {Component, EventEmitter, Output} from '@angular/core';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {AppConfig} from '../../core/modules/config/app-config';
import {environment} from '../../../environments/environment';

/**
 * Sidenav links component | displayed when logged in.
 */
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent {
  /**
   * Fetch the current app version from package-json (via environment variable).
   */
  appVersion = environment.version;

  /**
   * Collapse or expand icon, can be a right or left facing chevron.
   */
  collapseIcon = faChevronRight;

  sideBarLogo = 'assets/images/logo.png';

  /**
   * Boolean sidebar collapsed flag, used in template to display collapsed or expanded stylings.
   */
  collapsed = true;

  /**
   * EventEmitter for collapsed state change, used to propagate collapse changes to parent components.
   */
  @Output() collapseToggled = new EventEmitter<boolean>();

  /**
   * Component constructor.
   */
  constructor() {
    if (AppConfig.settings.sideBarLogo) {
      this.sideBarLogo = AppConfig.settings.sideBarLogo;
    }
  }

  /**
   * Collapse state change function, emits a collapseToggled event.
   */
  collapseToggle(): void {
    this.collapsed = !this.collapsed;
    this.collapseIcon = this.collapsed ? faChevronRight : faChevronLeft;
    this.collapseToggled.emit(this.collapsed);
  }
}
