<div #baseDialog class="dialog-container" >
  <div id="dialog" class="dialog">
    <div class="dialog-content">
      <!-- DIALOG HEADER -->
      <div class="header">
        <h1 style="text-transform: capitalize;">New {{config?.type}}</h1>
        <fa-icon class="close-icon" [icon]="closeIcon" [size]="'sm'" style="position: absolute; right: 31px; top: 24px;"
                 (click)="closeDialog($event, true)"></fa-icon>
      </div>
      <!-- DIALOG HEADER END -->
      <!-- DIALOG MAIN CONTENT -->
      <main>

        <app-resource-form #resourceForm [config]="config?.resourceFormConfig"
                           (valueChanges)="setResource($event)"></app-resource-form>

      </main>
      <!-- DIALOG MAIN CONTENT END -->
      <!-- DIALOG FOOTER -->
      <div class="cf footer">
        <button class="btn btn-md btn-cancel" (click)="closeDialog($event, true)">Cancel</button>
        <button class="btn btn-md btn-action" (click)="save()"
                [disabled]="!resourceFormGroup?.valid">Create
          <app-inline-spinner *ngIf="loading"></app-inline-spinner></button>
      </div>
      <!-- DIALOG FOOTER END -->
    </div>
    <div class="overlay" (click)="closeDialog($event, false)"></div>
  </div>
</div>
