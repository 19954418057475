import {Component, forwardRef, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteInputComponent),
      multi: true,
    },
  ],
})
export class AutocompleteInputComponent implements OnInit, ControlValueAccessor {
  onChange: any = () => {};
  onTouch: any = () => {};
  val = ''; // value of the component


  @ViewChild('autocomplete') autocomplete;

  @Input() options: any[];
  @Input() placeholder: string;
  textInput: any;
  selectedOption: any;
  private outsideClickBound: any;
  active: boolean;

  constructor(@Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.outsideClickBound = this.outsideClick.bind(this);
  }

  outsideClick({target}) {
    const appMenuItem = this.document.querySelector('.autocomplete-list');
    if (appMenuItem && !this.autocomplete.nativeElement.parentElement.contains(target)) {
      this.closeAutocomplete();
    }
  }

  closeAutocomplete() {
    this.active = false;
    this.document.body.removeEventListener('click', this.outsideClickBound);
  }


  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouch(val);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(value: any): void {
    if(!value) {
      this.textInput = '';
    }
    this.value = value;
  }

  selectOption(option: any) {
    this.value = option;
    this.selectedOption = option;
    this.textInput = option['name'];
    this.active = false;
  }

  setText(){
    this.active = true;
    this.value = this.textInput;
    delete this.selectedOption;
    this.document.body.addEventListener('click', this.outsideClickBound);
  }

}
