import {Component, OnInit, Output, ViewChild, EventEmitter} from '@angular/core';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {BaseDialogService} from "./base-dialog.service";

@Component({
  selector: 'app-base-dialog',
  templateUrl: './base-dialog.component.html',
  styleUrls: ['./base-dialog.component.css']
})
export class BaseDialogComponent implements OnInit {
  closeIcon = faTimes;
  @ViewChild('baseDialog', {static: false}) baseDialog;
  @Output() closed = new EventEmitter<boolean>();
  @Output() saved = new EventEmitter<boolean>();

  constructor(private baseDialogService: BaseDialogService) {
  }

  ngOnInit(): void {
  }

  openDialog(): void {
    this.baseDialog.nativeElement.classList.add('active');
    this.baseDialogService.toggle();
  }

  closeDialog(event, force, data = true): boolean {
    if (force) {
      this.baseDialog.nativeElement.classList.remove('active');
      this.closed.emit(true);
      this.baseDialogService.toggle();
      return true;
    } else if (event.target.closest('.overlay') &&
      !event.target.closest('button')) {
      this.baseDialog.nativeElement.classList.remove('active');
      this.closed.emit(true);
      this.baseDialogService.toggle();
      return true;
    }
    return false;
  }

  save(): void {
    this.saved.emit(true);
  }

  saveAndClose(): void {
    this.saved.emit(true);
    this.closeDialog(null, true);
  }
}
