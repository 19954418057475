<div #dropdown
     *ngIf="user"
     class="mini-profile" [ngClass]="{'active': dropdownActive}"
     (click)="toggleDropdown()">
  <img class="mini-profile-img"
       [src]="userDisplayImg"
       (error)="setPlaceholderImg()"
       alt="Profile image">
  <div class="mini-profile-content">
    <div [routerLink]="'/user-profile'" style="cursor: pointer;">
      <img class="mini-profile-large-img"
           alt="Profile image"
           [src]="userDisplayImg">
      <p class="mini-profile-name">
        <i *ngIf="(!user.first_name && user.first_name === '') && (!user.last_name && user.last_name === '') ">
          {{'common.nameNotSet' | translate }}
        </i>
        <span>
        {{user.first_name ? user.first_name : '' }} {{ user.last_name ? user.last_name : ''}}
      </span>
      </p>
      <p class="mini-profile-email">
        {{user.email ? user.email : 'email not set'}}
      </p>
    </div>
    <div class="mini-profile-link" (click)="signOut()">
      {{'common.logOut' | translate }}
    </div>
  </div>
</div>
