import {AfterViewInit, Component, EventEmitter, Inject, Input, Output, ViewChild} from '@angular/core';
import {DropDownMenuButton} from '@app/modules/custom-c-d-k/modules/list/list-table/subcomponents/dropdown-menu/dropdown-menu.component';
import {DOCUMENT} from '@angular/common';
import { faCaretUp, faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-menu-button',
    templateUrl: './menu-button.component.html',
    styleUrls: ['./menu-button.component.css']
})
export class MenuButtonComponent implements AfterViewInit {
    faCaretUp = faCaretUp;
    faSpinner = faSpinner;
    @Input() config: { mainButton: DropDownMenuButton; buttons: DropDownMenuButton[], loading: boolean };
    @ViewChild('dropdown') dropdown;
    private menuOutsideClickBound: any;

    @Output() buttonClick = new EventEmitter<string>();
    constructor(@Inject(DOCUMENT) private document: Document) {
    }

    ngAfterViewInit(): void {
        this.menuOutsideClickBound = this.menuOutsideClick.bind(this);
    }

    menuOutsideClick({target}): void {
        const appMenuItem = this.document.querySelector('.dropdown');
        if (appMenuItem && !this.dropdown.nativeElement.parentElement.contains(target)) {
            this.closeDropDown();
        }
    }

    closeDropDown(): void {
        this.dropdown.nativeElement.classList.remove('active');
        this.document.body.removeEventListener('click', this.menuOutsideClickBound);
    }

    showDropdown(): void {
        this.dropdown.nativeElement.classList.toggle('active');
        this.document.body.addEventListener('click', this.menuOutsideClickBound);
    }

    /*
      emitDropdownButtonClick(dropdownLink, actionLabel) => {
        dropdownLink.parentElement.classList.toggle('active');
        document.dispatchEvent(new CustomEvent("dropdownButtonClick", {detail: actionLabel}));
        alert(actionLabel);
      }*/
    dispatchButtonClick(buttonName: string): void {
        this.buttonClick.emit(buttonName);
    }
}
