<nav *ngIf="isSignedIn || unsignedFunctions"
        style="z-index: 1;">
    <app-sidebar (collapseToggled)="sidebarCollapseToggled($event)">
    </app-sidebar>
</nav>
<div class="content">
    <nav *ngIf="isSignedIn"
         class="main-navbar"
         [ngClass]="{'collapsed': sidebarCollapsed}">
    </nav>
    <main class="main-container">
        <router-outlet></router-outlet>
    </main>
</div>
