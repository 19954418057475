import {Component, EventEmitter, Inject, Input, Output, ViewChild} from '@angular/core';
import {faEllipsisH, faTrash, faDownload} from '@fortawesome/free-solid-svg-icons';
import {DOCUMENT} from '@angular/common';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

export class DropDownMenuButton {
  name: string;
  title: string;
  color?: string;
  icon?: IconProp;
  disabled?: boolean;
}
@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.css']
})
export class DropdownMenuComponent {
  ellipsisIcon = faEllipsisH;
  downloadIcon = faDownload;
  trashIcon = faTrash;

  @Input() buttons: DropDownMenuButton[];
  @ViewChild('dropdown') dropdown;
  @Output() buttonClick = new EventEmitter<string>();
  @Output() detailsClick = new EventEmitter<boolean>();
  @Output() deleteClick = new EventEmitter<boolean>();

  private menuOutsideClickBound: any;

  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  ngAfterViewInit() {
    this.menuOutsideClickBound = this.menuOutsideClick.bind(this);
  }

  menuOutsideClick({target}) {
    const appMenuItem = this.document.querySelector('.dropdown');
    if (appMenuItem && !this.dropdown.nativeElement.parentElement.contains(target)) {
      this.closeDropDown();
    }
  }

  closeDropDown() {
    this.dropdown.nativeElement.classList.remove('active');
    this.document.body.removeEventListener('click', this.menuOutsideClickBound);
  }

  showDropdown() {
    this.dropdown.nativeElement.classList.toggle('active');
    this.document.body.addEventListener('click', this.menuOutsideClickBound);
  }

  dispatchDetailsClick() {
    this.detailsClick.emit(true);
    this.closeDropDown();
  }

  dispatchDeleteClick() {
    this.deleteClick.emit(true);
    this.closeDropDown();
  }

  dispatchButtonClick(buttonName: string) {
    this.buttonClick.emit(buttonName);
  }
}
