import { Injectable } from '@angular/core';
import {fromEvent, Observable, Subject} from 'rxjs';
import {filter} from 'rxjs/operators';
import {Snackbar} from '../modules/custom-c-d-k/modules/snackbar/snackbar';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class NetworkStatusService {

  private networkStatus = new Subject<boolean>();

  get isOnline$(): Observable<any> {
    return this.networkStatus.pipe(
        filter(Boolean)
    );
  }

  handleAppConnectivityChanges(snackbar: Snackbar, translateService: TranslateService): void {
    const onlineEvent = fromEvent(window, 'online');
    const offlineEvent = fromEvent(window, 'offline');

    onlineEvent.subscribe(e => {
      // handle online mode
      snackbar.showInfo(translateService.instant('common.onlineMessage'));
      this.networkStatus.next(true);
    });

    offlineEvent.subscribe(e => {
      // handle offline mode
      snackbar.showError({}, 'common.offlineMessage');
      this.networkStatus.next(false);
    });
  }
}
