import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ResourceFormComponent} from './resource-form/resource-form.component';
import {LocationInputComponent} from './inputs/location-input/location-input.component';
import {FileInputComponent} from './inputs/file-input/file-input.component';
import {AutocompleteInputComponent} from './inputs/autocomplete-input/autocomplete-input.component';
import {ImageInputComponent} from './inputs/app-image-input/image-input.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ResourceDialogComponent} from './resource-dialog/resource-dialog.component';
import {ResourceSidebarComponent} from './resource-sidebar/resource-sidebar.component';
import {SpinnerModule} from '../spinner/spinner.module';
import {DateRangeComponent} from './inputs/date-range/date-range.component';
import {RangeInputComponent} from './inputs/range-input/range-input.component';
import {AbstractCustomInputComponent} from './inputs/abstract-custom-input/abstract-custom-input.component';
import {SharedModule} from '../../../../../shared/shared.module';
import { SwitchInputComponent } from './inputs/switch-input/switch-input.component';


@NgModule({
    declarations: [ResourceFormComponent, ResourceDialogComponent, ResourceSidebarComponent,

        LocationInputComponent,
        FileInputComponent,
        AutocompleteInputComponent,
        ImageInputComponent,
        DateRangeComponent,
        RangeInputComponent,
        AbstractCustomInputComponent,
        SwitchInputComponent],
    exports: [ResourceFormComponent, ResourceDialogComponent, ResourceSidebarComponent, AutocompleteInputComponent, ImageInputComponent, DateRangeComponent, RangeInputComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        SharedModule,
        SpinnerModule
    ]
})
export class ResourceModule {
}
