<div #dropdown class="dropdown" (click)="showDropdown()">
  <fa-icon [icon]="ellipsisIcon" style="cursor: pointer;padding: 10px;"></fa-icon>
  <div class="dropdown-content">
    <ng-container *ngFor="let dropdownButton of buttons">
      <div class="dropdown-link"
           [style.color]="!dropdownButton.disabled ? dropdownButton.color || 'black' : 'grey'"
           (click)="!dropdownButton.disabled && dispatchButtonClick(dropdownButton.name)">
        <ng-container *ngIf="dropdownButton.icon">
          <fa-icon [icon]="dropdownButton.icon" style="margin-right: 8px; " ></fa-icon>
        </ng-container>
        {{dropdownButton.title}}
      </div>
    </ng-container>
  </div>
</div>
