import {Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild} from '@angular/core';

// ol imports
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Map from 'ol/Map';
import View from 'ol/View';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import * as olProj from 'ol/proj';
import TileLayer from 'ol/layer/Tile';
import {Icon, Style} from 'ol/style';
import XYZ from 'ol/source/XYZ';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Geometry} from 'ol/geom';

export class Location {
  type = 'Point';
  coordinates: [number, number];
}
@Component({
  selector: 'app-location-input',
  templateUrl: './location-input.component.html',
  styleUrls: ['./location-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationInputComponent),
      multi: true,
    },
  ],
})
export class LocationInputComponent implements OnInit, ControlValueAccessor {

  constructor() { }

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouch(val);
  }
  @ViewChild('map', {static: false}) map;

  vectorSource: VectorSource<Geometry>;
  vectorLayer: VectorLayer<VectorSource<Geometry>>;
  marker: Feature<Point>;

  lon: number = undefined;
  lat: number = undefined;
  val = ''; // value of the component

  @Input() location: Location;
  @Output() locationChange = new EventEmitter<Location>();

  onChange: any = () => {};
  onTouch: any = () => {};

  ngOnInit(): void {
    const iconStyle = new Style({
      image: new Icon({
        anchor: [0.5, 46],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
        src: 'assets/images/select location.png'
      }),
    });

    // Feature and vector
    this.marker = new Feature({
      geometry: new Point(olProj.fromLonLat([16, 45.79]))
    });

    this.marker.setStyle(iconStyle);

    this.vectorSource = new VectorSource({
      features: [this.marker]
    });

    this.vectorLayer = new VectorLayer({
      source: this.vectorSource
    });

    this.map = new Map({
      target: 'mapInputPlaceholder',
      layers: [
        /*new TileLayer({
          source: new OSM()
        })*/
        new TileLayer({
          source: new XYZ({
            attributions: 'Copyright:© 2020 Esri, Maxar, Earthstar Geographics, CNES/Airbus DS, USDA FSA, USGS, Aerogrid, IGN, IGP, and the GIS User Community',
            url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
            maxZoom: 19
          })
        }),
      ],
      view: new View({
        center: olProj.fromLonLat([16, 45.79]),
        zoom: 10
      })
    });
    this.map.addLayer(this.vectorLayer);

    this.map.on('click', e => {
      const coordinates = olProj.transform(e.coordinate, 'EPSG:3857', 'EPSG:4326');

      this.lat = coordinates[1];
      this.lon = coordinates[0];
      this.changeLocation(this.lat , this.lon);
    });

  }

  changeLocation(latitude: number, longitude: number, ): void {
    const newLocation = new Location();
    newLocation.coordinates = [longitude, latitude];
    this.value = newLocation;
    this.marker.setGeometry(new Point(olProj.fromLonLat([longitude, latitude])));
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(value: any): void {
    if (!value) {
      delete this.lat;
      delete this.lon;
    }

    this.value = value;
  }

}
