import {Component, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: 'app-image-input',
    templateUrl: './image-input.component.html',
    styleUrls: ['./image-input.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ImageInputComponent),
            multi: true,
        },
    ]
})
export class ImageInputComponent implements OnInit, ControlValueAccessor {
    reader = new FileReader();
    readerQueue = [];
    readerFullQueue = [];
    closeIcon = faTimes;
    fileIcon = faPlus;
    onChange: any = () => {
    };
    onTouch: any = () => {
    };
    val = ''; // value of the component
    filename = '';
    @ViewChild('fileInput', {static: false}) fileInput;
    images = [];


    @Input() multiple = true;
    @Input() placeholder: string;
    @Input() fileTypes: string[];

    constructor() {
    }

    ngOnInit(): void {
        this.reader.onload = (e) => {
            const target: any = e.target;
            this.images.push(target.result);
            let nextFile = this.readerQueue.shift();
            if (nextFile) {
                this.reader.readAsDataURL(nextFile);
            }
        };

    }

    set value(val) {
        this.val = val;
        this.onChange(val);
        this.onTouch(val);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(value: any): void {
        this.value = value;

        if (!value) {
            delete this.filename;
            this.images = [];
        }
    }

    uploadFile(files: any) {

        if (this.multiple) {
            if (this.val) {
                this.value =Array.from(this.val).concat(Array.from(files));
            } else {
                this.value = files;
            }

            let j = 0;
            for (let i = 0; i < files.length; i++) {
                const fileType = files[i].type || files[i].name.split('.').length > 0 ? files[i].name.split('.').slice(-1)[0] : '';

                if (this.fileTypes.map(fileType => fileType.toLowerCase()).includes(fileType.toLowerCase())) {
                    if (i< 5) {
                        this.readerQueue.push(files[i]);
                    } else {
                        this.readerFullQueue.push(files[i]);
                    }
                }
                //this.value = [...this.value, files[i]];
            }

            let nextFile = this.readerQueue.shift();
            if (nextFile) {
                this.reader.readAsDataURL(nextFile);
            }
        } else {
            this.value = files;
            // TODO
            //this.reader.readAsDataURL(files[0]);
            this.value = files[0];
        }
    }

    deleteFile() {
        // TODO
        this.value = '';
        this.fileInput.nativeElement.value = '';
        this.filename = '';
    }

    deleteImage(image: any) {
        // TODO
        this.images = this.images.filter(array_image => {
            return array_image !== image;
        });
        this.value = '';
        this.fileInput.nativeElement.value = '';
        this.filename = '';
    }
}
