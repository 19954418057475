import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ConfirmDialogConfig} from '../../confirm-dialog/confirm-dialog.component';
import {first} from 'rxjs/operators';
import {faCaretDown, faCaretUp} from '@fortawesome/free-solid-svg-icons';
import {DropDownMenuButton} from './subcomponents/dropdown-menu/dropdown-menu.component';
import {AbstractRestService} from '../../../../../../data/services/resource/abstract-rest.service';

export enum DataType {
  TEXT = 'TEXT',
  GROUP = 'GROUP',
  NUMBER = 'NUMBER',
  BUTTON = 'BUTTON',
  MENU = 'MENU'
}

export class ListTableConfig {
  type?: string;
  actions?: DropDownMenuButton[];
  sortByColumnName?: string;
  sortOrderAscending?: boolean;
  columns: {
    name: string,
    headerText: string,
    dataType?: DataType,
    flexGrow?: number,
    justifyContent?: string;
    textAlign?: string,
    data?: {
      text?: string,
      groupName?: string
    }
    sortable?: boolean
  }[];

}

@Component({
  selector: 'app-list-table',
  templateUrl: './list-table.component.html',
  styleUrls: ['./list-table.component.css']
})

export class ListTableComponent implements  OnChanges{
  @ViewChild('confirmDeleteDialog', {static: false}) confirmDeleteDialog;

  @Input() config: ListTableConfig;
  @Input() data: any[];
  @Input() service: AbstractRestService<any, any>;
  DataType = DataType;
  @Output() groupListSelect = new EventEmitter<any>();
  @Output() deleteEntrySelect = new EventEmitter<any>();
  @Output() openEntrySelect = new EventEmitter<any>();

  @Output() sortSelect = new EventEmitter<string>();

  sortColumn: string;
  faCaretUp = faCaretUp;
  faCaretDown = faCaretDown;
  focusedEntry: any;
  @Input() confirmDeleteDialogConfig: ConfirmDialogConfig =
    {
      title: 'Delete',
      text: 'Are you sure you want to delete this resource',
      buttons: [
        {name: 'Cancel', class: 'btn-cancel'},
        {name: 'Delete', class: 'btn-danger'}
      ]
    };

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.service) {
      this.confirmDeleteDialogConfig.text = `Are you sure you want to delete this ${(this.service as any).modelName ? (this.service as any).modelName : 'resource'}`;
    }
  }

  selectGroupList(entry: any): void {
    this.groupListSelect.emit(entry);
  }

  downloadEntry(entry: any): void {
    // console.log("Entry download initiated for entry: " + JSON.stringify(entry));
  }

  openEntry(entry: any): void {
    this.openEntrySelect.emit(entry);
  }

  deleteEntry(entry: any): void {
    this.confirmDeleteDialog.openDialog();
    this.confirmDeleteDialog.buttonClick.pipe(first()).subscribe(button => {
      if (button === 'Delete' || button === 'Archive') {
        this.deleteEntrySelect.emit(entry);
      }
      this.confirmDeleteDialog.closeDialog(undefined, true);
    });
  }

  selectSort(sortString: string): void {
    this.config.sortOrderAscending = this.config.sortByColumnName === sortString ? !this.config.sortOrderAscending : true;
    this.config.sortByColumnName = sortString;
    this.sortSelect.emit(this.config.sortOrderAscending ? '' : '-' + sortString);
  }


  clickRow(event, entry: any): void {
    if (!event.target.closest('button')
      && !event.target.closest('fa-icon')
      && !event.target.closest('.group-link')
      && !event.target.closest('.dropdown-link')) {
      this.openEntrySelect.emit(entry);
    }
  }

  menuClickHandle(buttonName: string, entry: any): void {
    switch (buttonName) {
      case 'DELETE': {
        this.deleteEntry(entry);
        break;
      }
      case 'DETAILS': {
        this.openEntry(entry);
        break;
      }
      default: {
        break;
      }
    }
  }
}

