<form [formGroup]="resourceFormGroup">
    <div *ngIf="config">
        <ng-container *ngFor="let field of config.fields">
            <div class="form-element" [class]="field.class" [ngSwitch]="field.type">
                <!-- DEFAULT - TEXT INPUT TYPE -->
                <div *ngSwitchDefault>
                    <label [for]="field.name">{{field.label ? field.label : field.name}}</label>
                    <input class="w-100" type="text"
                           [name]="field.name"
                           [id]="field.name"
                           [maxLength]="field.maxLength ? field.maxLength : 524288"
                           [autocomplete]="field.autocomplete"
                           [formControl]="field.control"
                           [ngClass]="{'unvalid': field.control.touched && !field.control.valid}"
                           [placeholder]="field.placeholder || ''"
                           [readOnly]="field.readonly"
                    >
                </div>
                <!-- AUTOCOMPLETE INPUT TYPE -->
                <div *ngSwitchCase="FieldType.AUTOCOMPLETE">
                    <label [for]="field.name">{{field.label ? field.label : field.name}}</label>
                    <app-autocomplete-input [formControl]="field.control"
                                            [options]="field.options"
                                            [placeholder]="field.placeholder || ''"
                    ></app-autocomplete-input>
                </div>
                <!-- BOOLEAN INPUT TYPE -->
                <div *ngSwitchCase="FieldType.BOOLEAN">
                    <label [for]="field.name">{{field.label ? field.label : field.name}}</label>
                    <label class="checkbox-container w-100">Yes
                        <input type="checkbox"
                               [name]="field.name"
                               [id]="field.name"
                               [formControl]="field.control"
                               [ngClass]="{'unvalid': field.control.touched && !field.control.valid}"
                               [readOnly]="field.readonly">
                        <span class="checkmark"></span>
                    </label>
                </div>
                <!-- SWITCH INPUT TYPE -->
                <div *ngSwitchCase="FieldType.SWITCH">
                    <label [for]="field.name">{{field.label ? field.label : field.name}}</label>
                    <app-switch-input [formControl]="field.control"
                                      [placeholder]="field.placeholder"
                                      [readonly]="field.readonly">
                    </app-switch-input>
                </div>
                <!-- DATE INPUT TYPE -->
                <div *ngSwitchCase="FieldType.DATE">
                    <label [for]="field.name">{{field.label ? field.label : field.name}}</label>
                    <app-date-range ngDefaultControl
                                    [singleDate]="true"
                                    [id]="field.name"
                                    [formControl]="field.control"
                                    [ngClass]="{'unvalid': field.control.touched && !field.control.valid}"
                                    [placeholder]="field.placeholder || ''"
                                    [readOnly]="field.readonly"
                    ></app-date-range>
                </div>
                <!-- DATE INPUT TYPE -->
                <div *ngSwitchCase="FieldType.DATERANGE">
                    <app-date-range ngDefaultControl
                                    [id]="field.name"
                                    [formControl]="field.control"
                                    [ngClass]="{'unvalid': field.control.touched && !field.control.valid}"
                                    [placeholder]="field.placeholder || ''"
                    ></app-date-range>
                </div>
                <!-- NUMBER INPUT TYPE -->
                <div *ngSwitchCase="FieldType.DATETIME">
                    <label [for]="field.name">{{field.label ? field.label : field.name}}</label>
                    <input class="w-100" type="datetime-local"
                           [name]="field.name"
                           [id]="field.name"
                           [formControl]="field.control"
                           [ngClass]="{'unvalid': field.control.touched && !field.control.valid}"
                           [placeholder]="field.placeholder || ''"
                           [readOnly]="field.readonly">
                </div>
                <!-- FILE INPUT TYPE -->
                <div *ngSwitchCase="FieldType.FILE">
                    <label [for]="field.name">{{field.label ? field.label : field.name}}</label>
                    <app-file-input [formControl]="field.control"
                                    [placeholder]="field.placeholder"
                                    [fileTypes]="field.fileTypes"
                                    [disabled]="field.disabled"
                    ></app-file-input>
                </div>
                <!-- IMAGE INPUT TYPE -->
                <div *ngSwitchCase="FieldType.IMAGE">
                    <label [for]="field.name">{{field.label ? field.label : field.name}}
                        <span *ngIf="field.control.value?.length > 0">({{field.control.value.length}})</span>
                    </label>
                    <app-image-input [formControl]="field.control"
                                     [placeholder]="field.placeholder"
                                     [fileTypes]="field.fileTypes"
                    ></app-image-input>
                </div>
                <!-- INTEGER INPUT TYPE -->
                <div *ngSwitchCase="FieldType.INTEGER">
                    <label [for]="field.name">{{field.label ? field.label : field.name}}</label>
                    <input class="w-100" type="number" step="1"
                           [name]="field.name"
                           [id]="field.name"
                           [maxLength]="field.maxLength ? field.maxLength : 524288"
                           [formControl]="field.control"
                           [ngClass]="{'unvalid': field.control.touched && !field.control.valid}"
                           [placeholder]="field.placeholder || ''"
                           [readOnly]="field.readonly">
                </div>
                <!-- LOCATION INPUT TYPE -->
                <div *ngSwitchCase="FieldType.LOCATION">
                    <label [for]="field.name">{{field.label ? field.label : field.name}}</label>
                    <app-location-input [formControl]="field.control"></app-location-input>
                </div>
                <!-- NUMBER INPUT TYPE -->
                <div *ngSwitchCase="FieldType.NUMBER">
                    <label [for]="field.name">{{field.label ? field.label : field.name}}</label>
                    <input class="w-100" type="number"
                           [name]="field.name"
                           [id]="field.name"
                           [maxLength]="field.maxLength ? field.maxLength : 524288"
                           [formControl]="field.control"
                           [ngClass]="{'unvalid': field.control.touched && !field.control.valid}"
                           [placeholder]="field.placeholder || ''"
                           [readOnly]="field.readonly">
                </div>
                <!-- PASSWORD INPUT TYPE -->
                <div *ngSwitchCase="FieldType.PASSWORD">
                    <label [for]="field.name">{{field.label ? field.label : field.name}}</label>
                    <input class="w-100" type="password"
                           [name]="field.name"
                           [id]="field.name"
                           [maxLength]="field.maxLength ? field.maxLength : 524288"
                           [autocomplete]="field.autocomplete"
                           [formControl]="field.control"
                           [ngClass]="{'unvalid': field.control.touched && !field.control.valid}"
                           [placeholder]="field.placeholder || ''"
                           [readOnly]="field.readonly">
                </div>
                <!-- SELECT INPUT TYPE -->
                <div *ngSwitchCase="FieldType.SELECT">
                    <label [for]="field.name">{{field.label ? field.label : field.name}}</label>
                    <select class="search-select w-100" [formControl]="field.control">
                        <!--<option value="none" disabled selected hidden>{{searchField.headerText}}</option>-->
                        <!--<option value="none">None</option>-->
                        <ng-container *ngFor="let option of field.options">
                            <option [value]="option['value']">
                                {{option['name']}}
                            </option>
                        </ng-container>
                    </select>
                </div>
                <!-- TEXTAREA INPUT TYPE -->
                <div *ngSwitchCase="FieldType.TEXTAREA">
                    <label [for]="field.name">{{field.label ? field.label : field.name}}</label>
                    <textarea class="w-100"
                              [name]="field.name"
                              [id]="field.name"
                              [maxLength]="field.maxLength ? field.maxLength : 524288"
                              [formControl]="field.control"
                              [ngClass]="{'unvalid': field.control.touched && !field.control.valid}"
                              [placeholder]="field.placeholder || ''"
                              [readOnly]="field.readonly">
              </textarea>
                </div>
            </div>
        </ng-container>
    </div>
</form>
