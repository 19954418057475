import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import {concat, Observable, throwError} from 'rxjs';
import {NetworkStatusService} from '@app/services/network-status.service';
import {catchError, first, ignoreElements} from 'rxjs/operators';

@Injectable()
export class OfflineInterceptor implements HttpInterceptor {

    constructor(private networkStatusService: NetworkStatusService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError(err => {
                if (err.status === 0) {
                    return concat(
                        // We're first waiting for the connection to become available.
                        // `ignoreElements` - will ignore `next` notifications. But it will let `error/complelete`
                        // notifications pass through.
                        this.networkStatusService.isOnline$.pipe(first(), ignoreElements()),
                        // After we're back online, we make the request once again.
                        next.handle(req),
                    );
                }
                return throwError(err);
            })
        );
    }
}
