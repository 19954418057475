import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faBars, faSearch, faThLarge} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {Subject, Subscription} from "rxjs";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {FieldType} from "../../resource/resource-form/resource-form.component";

export class ListHeaderConfig {
  type: string;
  typePlural: string;
  viewsSwitch?: boolean;
  buttons?: { name: string, text: string, icon?: IconProp, iconImg?: string, class?: string, disabled?: boolean }[];
  searchPullDown?: boolean;
  filterFields?: any[];
}

export enum ListType {
  CARDS = 'CARDS',
  TABLE = 'TABLE'
}


@Component({
  selector: 'app-list-header',
  templateUrl: './list-header.component.html',
  styleUrls: ['./list-header.component.css']
})
export class ListHeaderComponent implements OnInit {
  FieldType = FieldType;
  cardViewIcon = faThLarge;
  tableView = faBars;
  searchIcon = faSearch;
  ListType = ListType;
  viewType = ListType.TABLE;

  @Input() config: ListHeaderConfig;
  @Input() loading: boolean;
  @Input() objectsCount: number;
  @Output() createNewClicked = new EventEmitter<boolean>();
  @Output() buttonClicked = new EventEmitter<string>();
  @Output() searchChange = new EventEmitter<string>();
  @Output() sortSelectChange = new EventEmitter<any>();
  searchValue: string;
  searchTextChanged = new Subject<string>();
  searchTextChange$: Subscription;

  constructor() {
  }

  ngOnInit(): void {
    this.searchTextChanged.pipe(
      debounceTime(500),
      distinctUntilChanged()).subscribe(() => {
        this.searchChangeEmit();
    })
  }

  createButtonClick() {
    this.createNewClicked.emit(true);
  }

  buttonClick(name: string) {
    this.buttonClicked.emit(name);
  }

  searchChangeEmit() {
    this.searchChange.emit(this.searchValue);
  }

  filterChangeEmit(name: string, value: string) {
    this.sortSelectChange.emit({name: name, value: value})
  }
}
