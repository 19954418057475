import {Component, ElementRef, Input, OnInit, EventEmitter, Output, Renderer2} from '@angular/core';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'app-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.css'],
    providers: [FaIconComponent]
})
export class SnackbarComponent implements OnInit {
    isSnackbarComponent = true;
    type = 'SUCCESS';
    closeIcon = faTimes;
    @Input() content;
    @Output() afterClose = new EventEmitter();

    ngOnInit(): void {
        const animate = value => this.renderer.setStyle(this.container, 'animation', value);

        animate(`snackbarIn 0.5s, snackbarOut 0.5s ${5000}ms`);
    }

    animationDone(event: AnimationEvent): void {
        if (event.animationName === 'snackbarOut') {
            this.afterClose.emit(true);
        }
    }

    constructor(private host: ElementRef<HTMLElement>, private renderer: Renderer2,) {
    }

    get container(): HTMLElement {
        return this.host.nativeElement.querySelector('.snackbar-container') as HTMLElement;
    }

    close(): void {
        this.container.style.animation = 'snackbarOut 0.3s';
    }
}
