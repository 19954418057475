<div class="list-header-container">
  <div class="row">
    <div class="col-xs-12 col-md-8">
      <!--<ng-container *ngIf="loading">
        <app-placeholder [width]="'335px'" [height]="'40px'"></app-placeholder>
      </ng-container>-->
      <div class="projects-search-cnt"
           [ngClass]="{'pull-down': config.searchPullDown}">
        <label class="projects-cnt" for="search" > {{objectsCount || 0}}
          {{config?.typePlural ? config.typePlural : 'entries'}}</label>
        <div class="search-container">
          <fa-icon class="search-input-icon" [icon]="searchIcon" [size]="'sm'"></fa-icon>
          <input class="projects-search"
                 type="text"
                 id="search"
                 name="search"
                 (input)="searchChangeEmit()"
                 [(ngModel)]="searchValue"
                 [placeholder]="'Search for ' +  (config?.typePlural ? config.typePlural : 'entries') + '...'">

          <ng-container *ngFor="let searchField of config.filterFields">
            <div class="form-element" *ngIf="searchField.type === FieldType.SELECT">
              <select class="search-select "
                      [(ngModel)]="searchField.value"
                      (ngModelChange)="filterChangeEmit(searchField.name, searchField.value)"
              [style.color]="searchField.value === 'none' ? '#757575' : '#4E5D78'">
                <ng-container *ngIf="searchField.selectAll">
                  <option value="none" disabled selected hidden>{{searchField.headerText}}</option>
                  <option value="none">All</option>
                </ng-container>

                <option *ngFor="let option of searchField['options']" [value]="option['value']">
                  {{option['name']}}
                </option>

              </select>
            </div>
            <div class="form-element" *ngIf="searchField.type === FieldType.AUTOCOMPLETE">
              <app-autocomplete-input [(ngModel)]="searchField.value"
                                      [options]="searchField.options"
                                      [placeholder]="searchField.headerText"
                                      (ngModelChange)="filterChangeEmit(searchField.name, $event)"
              ></app-autocomplete-input>
            </div>
            <div class="form-element" *ngIf="searchField.type === FieldType.DATERANGE">
              <app-date-range ngDefaultControl [(ngModel)]="searchField.value"
                              [placeholder]="searchField.headerText"
                                      (ngModelChange)="filterChangeEmit(searchField.name, searchField.value)"
              ></app-date-range>
            </div>

          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-md-4 end-xs">
      <div class="buttons" *ngIf="config?.buttons?.length > 0">
        <ng-container *ngFor="let button of config.buttons">

          <!--<ng-container *ngIf="loading">
            <app-placeholder [width]="'184px'" [height]="'38px'"></app-placeholder>
          </ng-container>-->
          <button class="btn btn-wide"
                  [ngClass]="button.class? button.class : ''"
                  (click)="buttonClick(button.name)"
                  [disabled]="button.disabled">
            <fa-icon class="button-icon" [icon]="button.icon" [size]="'sm'" *ngIf="button.icon"></fa-icon>
            <img class="button-img" [src]="button.iconImg" [alt]="button.name" *ngIf="button.iconImg">
            {{ button.text }}
          </button>

        </ng-container>

        <!--<button class="btn btn-action create-button" (click)="createButtonClick()">
          <fa-icon class="close-icon" [icon]="plusIcon" [size]="'sm'"></fa-icon>
          Create {{config?.type ? config.type : 'object'}}
        </button>-->
      </div>
      <!-- SORT & SWITCHES -->
      <!--<ng-container *ngIf="loading">
        <app-placeholder [width]="'270px'" [height]="'18px'"></app-placeholder>
      </ng-container>-->
      <ng-container>
        <div class="sort-container">
          <!--<label class="sort-label" for="sort-select">Sort by</label>
          <select class="sort-select" id="sort-select" name="sort-select">
            <option>Last Modified</option>
          </select>-->
        </div>
        <div class="views-switches" *ngIf="config?.viewsSwitch">
          <fa-icon class="view-type-icon active" [ngClass]="{'active': viewType === ListType.CARDS}"
                   [icon]="cardViewIcon"
                   (click)="viewType = ListType.CARDS; buttonClick(viewType)"></fa-icon>
          <fa-icon class="view-type-icon" [ngClass]="{'active': viewType === ListType.TABLE}"
                   [icon]="tableView"
                   (click)="viewType = ListType.TABLE; buttonClick(viewType)"></fa-icon>
        </div>
      </ng-container>
      <!-- SORT & SWITCHES END -->
    </div>
  </div>
</div>
