import {
    Compiler, Component,
    ComponentFactoryResolver,
    Inject,
    Injectable,
    Injector,
} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {SnackbarComponent} from './snackbar.component';
import {SnackbarModule} from './snackbar.module';

@Injectable()
export class Snackbar {

    constructor(private resolver: ComponentFactoryResolver,
                private injector: Injector,
                @Inject(DOCUMENT) private document: Document,
                private compiler: Compiler
    ) {
    }

    open(content: any): void {
        const mod = this.compiler.compileModuleAndAllComponentsSync(SnackbarModule);
        /*const factory = mod.componentFactories.find((comp) =>
            comp.componentType === SnackbarComponent
        );*/
        const factory = this.resolver.resolveComponentFactory(SnackbarComponent);

        const componentRef = factory.create(this.injector);
        componentRef.instance.content = content;
        componentRef.hostView.detectChanges();
        const {nativeElement} = componentRef.location;
        this.document.body.appendChild(nativeElement);

        componentRef.instance.afterClose.subscribe(() => {
            componentRef.destroy();
            this.document.body.removeChild(nativeElement);
        });
    }

    showError(error: any, text: string, forceManualClose: boolean = true): void {
        let additionalInfo = '';

        Object.keys(error).map(key => {
            if (typeof error[key] === 'string') {
                additionalInfo += `<b>${key}</b>: ${error[key]}<br>`;
            } else if (typeof error[key] === 'object') {
                additionalInfo += `<b>${key}</b>: ${JSON.stringify(error[key])}<br>`;
            }
        });
        this.open({
            forceManualClose,
            success: false,
            text,
            additionalInfo
        });
    }

    showInfo(text: string, additionalInfo = null, forceManualClose: boolean = true): void {
        this.open({
            success: true,
            text,
            additionalInfo
        });
    }

}
