// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    version: '1.16.0',
    production: false,
    apiBase: 'https://mapper-dev-api.terryspace.io/',
    appBase: 'https://mapper-dev.terryspace.io/',
    apiDrone: 'https://pg.terryx.ch',
    defaultLanguage: 'en',
    cesiumAccessToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmZmNlMzhmMi05YzlmLTQ4YjctYWE2Ny0wNjA4ODU4NmM0YmYiLCJpZCI6NDQxMzAsImlhdCI6MTYxMzY0MjUyM30.Bjjf3ejMxxryOEM-lJm3K7bUgccCXuGU7KxkyKlAWMk',
    terryxApiBase: 'https://api.stg.interradev.ch/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
