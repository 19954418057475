import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BaseDialogComponent} from './components/base-dialog/base-dialog.component';
import {SidebarTabsComponent} from './components/right-sidebar/sidebar-tabs/sidebar-tabs.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ResourceModule} from './modules/resource/resource.module';
import {ListModule} from './modules/list/list.module';
import {SnackbarModule} from './modules/snackbar/snackbar.module';
import {SpinnerModule} from './modules/spinner/spinner.module';
import {ConfirmDialogModule} from './modules/confirm-dialog/confirm-dialog.module';
import {RightSidebarComponent} from './components/right-sidebar/right-sidebar.component';
import {CardComponent} from './components/card/card.component';
import {TranslateModule} from '@ngx-translate/core';
import { MenuButtonComponent } from './components/menu-button/menu-button.component';
import { SkeletonComponent } from './components/skeleton/skeleton.component';


@NgModule({
    declarations: [BaseDialogComponent, CardComponent, SidebarTabsComponent,
        RightSidebarComponent, CardComponent, MenuButtonComponent, SkeletonComponent],
    imports: [
        CommonModule,
        FontAwesomeModule,
        ListModule,
        ResourceModule,
        SnackbarModule,
        SpinnerModule,
        TranslateModule
    ],
    exports: [
        // COMPONENTS
        BaseDialogComponent,
        CardComponent,
        SidebarTabsComponent,
        RightSidebarComponent,
        // MODULES
        ConfirmDialogModule,
        ListModule,
        ResourceModule,
        SnackbarModule,
        SpinnerModule,
        MenuButtonComponent,
        SkeletonComponent
    ],
})
export class CustomCDKModule {
}
