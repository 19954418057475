import {Component, OnInit, Output, ViewChild, EventEmitter, Input, AfterViewInit} from '@angular/core';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {BaseDialogComponent} from '../../../components/base-dialog/base-dialog.component';
import {UntypedFormGroup} from '@angular/forms';
import {BaseDialogService} from '../../../components/base-dialog/base-dialog.service';
import {
  ResourceFormConfig
} from '../resource-form/resource-form.component';
import {Subject} from 'rxjs';
import {Snackbar} from '../../snackbar/snackbar';
import {AbstractRestService} from '../../../../../../data/services/resource/abstract-rest.service';

@Component({
  selector: 'app-resource-dialog',
  templateUrl: './resource-dialog.component.html',
  styleUrls: ['./resource-dialog.component.css']
})
export class ResourceDialogComponent extends BaseDialogComponent implements  AfterViewInit {
  loading: boolean;
  closeIcon = faTimes;
  @ViewChild('baseDialog', {static: false}) baseDialog;
  @ViewChild('resourceForm', {static: false}) resourceForm;
  @Output() closed = new EventEmitter<boolean>();
  @Input() service: AbstractRestService<any, any>;
  @Input() config: { type: string, resourceFormConfig: ResourceFormConfig };

  resetFormSubject: Subject<boolean>;
  resourceFormGroup: UntypedFormGroup;

  constructor(baseDialogService: BaseDialogService, private snackbar: Snackbar) {
    super(baseDialogService);
  }

  ngAfterViewInit(): void {
    this.resetFormSubject = this.resourceForm.getResetFormSubject();
  }

  closeDialog(event, force, data: boolean = true): boolean {
    if (super.closeDialog(event, force, data)) {
      this.resetFormSubject.next(true);
      return true;
    }
    return false;
  }

  setResource(regulationFormGroup: UntypedFormGroup): void {
    this.resourceFormGroup = regulationFormGroup;
  }

  resetForm(): void {
    const resourceFormConfigTemp = this.config.resourceFormConfig;
    this.config.resourceFormConfig = {fields: []};
    this.config.resourceFormConfig = resourceFormConfigTemp;
  }

  save(): void {
    this.loading = true;
    this.service.createOne(this.resourceFormGroup.value).subscribe(res => {
      this.loading = false;
      this.snackbar.open({
        success: true,
        text: `${(this.service as any).modelName ? (this.service as any).modelName : 'resource'} ${res.id} successfully created`
          .replace(/^\w/, (c) => c.toUpperCase())
      });
    }, error => {
      this.snackbar.open({
        success: false,
        text: `Error Creating the ${(this.service as any).modelName ? (this.service as any).modelName : 'resource'}!`
      });
      this.loading = false;
    });
    super.saveAndClose();
    this.resetFormSubject.next(true);
  }
}
