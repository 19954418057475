<div style=" height: 60px; width: 100%;" *ngIf="!val || multiple">
    <div class="upload-file-container parent" (click)="fileInput.click()" appDragAndDrop
         (onFileDropped)="uploadFile($event)">
        <div class="child_1 center-sm">
            <img src="../../../../../../../../assets/images/image.png">
            <p style="margin: 0;white-space: nowrap;color: #8D919A; font-size: 12px;">
                {{placeholder || 'Drop your image here or click to upload'}}
            </p>
        </div>
    </div>
</div>

<div class="meter" *ngIf="readerQueue.length > 0">
    <span [style.width]="(((5 - readerQueue.length) / 5) * 100) +  '%'"></span>
</div>

<div style="
  white-space: nowrap;">
    <!--<div *ngFor="let image of images.slice(0, 5)" style="display: inline-block; margin-top: 25px; position: relative;-->
    <div *ngFor="let image of images.slice(0, 5)" style="display: inline-block; margin-top: 10px; position: relative;
">
        <img [src]="image" style="height: 65px; width: 65px; margin-right: 15px; border-radius: 3px;">
        <!--<fa-icon [icon]="closeIcon" [size]="'sm'" (click)="deleteImage(image)" style="right: 30px;
    position: absolute;
    top: 5px;
    color: white;
    cursor: pointer;"></fa-icon>-->
    </div>
</div>


<!-- accept="image/*"  -->
<input hidden type="file" #fileInput (change)="uploadFile($event.target['files'])" [multiple]="multiple" accept="image/*"
       style="display: none;">
