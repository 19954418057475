<input class="w-100" type="text" [(ngModel)]="textInput" (keyup)="setText()" [placeholder]="placeholder ? placeholder : ''">
<div style="position: relative;">
  <div  #autocomplete class="autocomplete-list"
  [ngClass]="{'active': active}">
    <div  *ngFor="let option of options">
      <div (click)="selectOption(option)"
                    *ngIf="!selectedOption && textInput
                    && option.name.toLowerCase().includes(textInput.toLowerCase())">
        <div class="autocomplete-list-option">{{option.name}}</div>
      </div>
    </div>
  </div>

</div>
