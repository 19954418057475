import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard, NonAuthGuard} from '@app/modules/auth/auth.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/sites',
        pathMatch: 'full'
    },
    {
        path: 'sites',
        loadChildren: () => import('./pages/map-view/map-view.module').then(m => m.MapViewModule),
    }, {
        path: 'signin',
        canActivate: [NonAuthGuard],
        loadChildren: () => import('./pages/sign-in/sign-in.module').then(m => m.SignInModule),
    },
    {
        path: 'password-reset',
        canActivate: [NonAuthGuard],
        loadChildren: () => import('./pages/sign-in/sign-in.module').then(m => m.SignInModule),
    },
    {
        path: 'user-profile',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/user-profile/user-profile.module').then(m => m.UserProfileModule),
    }
    /*{
      path: '**',
      component: NotFoundComponent
    }*/
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
