export class User {
  id: number;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  img: string;
  language_preference: string;
  company_name: string;
  country: number;
  default_crs: number;
  default_vertical_datum: number;
}
