<div [style.width]="width? width : '70%'"
     [style.height]="height? height : '24px'"
     [style.border-radius]="circle? '50%' : 'none'"
     [style.padding-top]="paddingTop ? paddingTop: '0' "
     style="display: inline-block;
    height: 24px;
    background: linear-gradient(to right,#eee 8%,#ddd 18%,#eee 33%);
    background-size: 1000% 100%;
    -webkit-animation: 1s linear 0s infinite forwards normal animation2;
    animation: 1s linear 0s infinite forwards normal animation2;"></div>
