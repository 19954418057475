<div class="sidenav">
  <img [src]="sideBarLogo" class="sidenav-logo" alt="terrymapper logo"
       [routerLink]="'/'">
  <img src="assets/images/vector.png" class="sidenav-vector-logo" alt="terrymapper vector logo"
       [routerLink]="'/'">
  <div class="sidenav-collapse-container">
  </div>
  <div class="sidenav-links">
    <ul class="sidenav-links">
      <li style="position: absolute; bottom: 80px; width: 24px; cursor: initial;">
        <img class="link-icon" src="../../../assets/images/terryx-logo.png" alt="Powered by in-terra"
             style="height: 20px;">
        <span class="link-text"
              style="margin-left: -2px;font-size: 10px;height: auto;line-height: 12px;padding: 10px; width: 100px;">
          <span style="font-weight: bold;">Powered by in-terra</span> <br> App version <i>{{appVersion}}</i></span>
      </li>
    </ul>
  </div>
  <app-mini-profile></app-mini-profile>
</div>

