import {AppConfigInterface} from './app-config-interface';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable()
export class AppConfig {
    static settings: AppConfigInterface;

    constructor(private http: HttpClient) {
    }

    public load(): Promise<AppConfigInterface> {
        let jsonFile = '';
        switch (location.host) {
            case 'localhost:4200': {
                jsonFile = `assets/config/app.config.terrymapper.json`;
                break;
            }
            case 'valamap.valalanche.info': {
                jsonFile = `assets/config/app.config.valamap.json`;
                break;
            }
            case 'sbb-terrymapper.terryx.ch': {
                jsonFile = `assets/config/app.config.sbb-terrymapper.json`;
                break;
            }
            case 'ebimapper.terryx.ch': {
                jsonFile = `assets/config/app.config.ebimapper.json`;
                break;
            }
            case 'rhonemapper.terryx.ch': {
                jsonFile = `assets/config/app.config.rhonemapper.json`;
                break;
            }
            case 'mapper.terryspace.io': {
                jsonFile = `assets/config/app.config.terrymapper.json`;
                break;
            }
            case 'dev-mapper.terryspace.io': {
                jsonFile = `assets/config/app.config.terrymapper-dev.json`;
                break;
            }
            default: {
                jsonFile = `assets/config/app.config.default.json`;
                break;
            }
        }

        return new Promise<AppConfigInterface>((resolve, reject) => {
            this.http.get(jsonFile).toPromise().then((response: AppConfigInterface) => {
                AppConfig.settings = (response as AppConfigInterface);
                resolve(response);
            }).catch((response: any) => {
                reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
            });
        });
    }


}

export function initializeApp(appConfig: AppConfig): () => Promise<AppConfigInterface> {
    return () => appConfig.load();
}
