import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SpinnerComponent} from './spinner.component';
import {InlineSpinnerComponent} from './inline-spinner.component';
import { PlaceholderComponent } from './placeholder/placeholder.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [SpinnerComponent, InlineSpinnerComponent, PlaceholderComponent],
    exports: [SpinnerComponent, InlineSpinnerComponent, PlaceholderComponent]
})
export class SpinnerModule {
}


