import {Component, Input, OnInit, EventEmitter, Output} from '@angular/core';
import {faShareAlt, faTrash, faDownload} from '@fortawesome/free-solid-svg-icons';

export class Resource {
  id: number;
  name: string;
  description: string;
  image: string;
  date_time: string;
  status?: {label: string, color: string};
  tooltip?: string;
  displayMenu?: boolean;
  backgroundColor?: any;
}
@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {
  faShareAlt = faShareAlt;
  faTrash = faTrash;
  faDownload = faDownload;

  @Output() menuButtonClick = new EventEmitter<string>();
  @Input() resource: Resource;
  @Input() config: any;

  constructor() { }

  ngOnInit(): void {
  }


  menuClickHandle(buttonName: string): void {
    this.menuButtonClick.emit(buttonName);
  }

  setPlaceholderImg() {
    //
  }
}
