import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {RightSidebarComponent} from '../../../components/right-sidebar/right-sidebar.component';
import {ResourceFormConfig} from '../resource-form/resource-form.component';
import {UntypedFormGroup} from '@angular/forms';
import {Snackbar} from '../../snackbar/snackbar';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {Subject} from 'rxjs';
import {AbstractRestService} from '../../../../../../data/services/resource/abstract-rest.service';

@Component({
  selector: 'app-resource-sidebar',
  templateUrl: './resource-sidebar.component.html',
  styleUrls: ['./resource-sidebar.component.css']
})
export class ResourceSidebarComponent extends RightSidebarComponent implements OnInit, AfterViewInit {
  loading: boolean;
  closeIcon = faTimes;
  @ViewChild('resourceForm', {static: false}) resourceForm;
  @Output() closed = new EventEmitter<boolean>();
  @Input() service: AbstractRestService<any, any>;
  @Input() config: { type: string, resourceFormConfig: ResourceFormConfig };

  resetFormSubject: Subject<boolean>;
  resourceFormGroup: UntypedFormGroup;


  constructor(private snackbar: Snackbar) {
    super();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.resetFormSubject = this.resourceForm.getResetFormSubject();
  }


  setResource(regulationFormGroup: UntypedFormGroup): void {
    this.resourceFormGroup = regulationFormGroup;
  }

  resetForm(): void {
    const resourceFormConfigTemp = this.config.resourceFormConfig;
    this.config.resourceFormConfig = {fields: []};
    this.config.resourceFormConfig = resourceFormConfigTemp;
  }

  save(closeSidebar: boolean = false): void {
    this.loading = true;
    this.service.createOne(this.resourceFormGroup.value).subscribe(res => {
      this.loading = false;
      this.snackbar.open({
        success: true,
        text: `${(this.service as any).modelName ? (this.service as any).modelName : 'resource'} ${res.id} successfully created`
          .replace(/^\w/, (c) => c.toUpperCase())
      });
    }, error => {
      this.snackbar.open({
        success: false,
        text: `Error Creating the ${(this.service as any).modelName ? (this.service as any).modelName : 'resource'}!`
      });
      this.loading = false;
    });

    if (closeSidebar) {
      super.closeSidebar(undefined, true);
    }
    this.resetFormSubject.next(true);
  }

}
