import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class AppendEndingSlashInterceptor implements HttpInterceptor {

    constructor() {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!(req.url.includes('/assets/') || req.url.endsWith('.zip') || req.url.endsWith('.json')
            || req.url.endsWith('.xml')) && req.url.slice(-1) !== '/') {
            const cloneReq = req.clone({url: req.url + '/'});
            return next.handle(cloneReq);
        }

        return next.handle(req.clone());
    }
}
