import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {SidebarComponent} from './layout/sidebar/sidebar.component';
import {AppRoutingModule} from './app-routing.module';
import {MiniProfileComponent} from './layout/sidebar/mini-profile/mini-profile.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AppendEndingSlashInterceptor} from '@app/interceptors/append-ending-slash-interceptor';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppConfig, initializeApp} from '@app/modules/config/app-config';
import {OfflineInterceptor} from '@app/interceptors/offline.interceptor';
import {CoreModule} from '@app/core.module';

@NgModule({
    declarations: [
        AppComponent,
        SidebarComponent,
        MiniProfileComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FontAwesomeModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient]
            }
        }),
        CoreModule
    ],
    providers: [
        AppConfig,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppConfig],
            multi: true
        }, {
            provide: HTTP_INTERCEPTORS,
            useClass: AppendEndingSlashInterceptor,
            multi: true
        }, {
            provide: HTTP_INTERCEPTORS,
            useClass: OfflineInterceptor,
            multi: true
        }],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}

// AOT compilation support
export function httpTranslateLoader(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}
