<button class="btn btn-md btn-action" style="padding: 8px;
    border-radius: 4px 0 0 4px;" (click)="dispatchButtonClick(config?.mainButton.name)"
        [disabled]="!!config?.mainButton?.disabled">
    <span class="label">{{config?.mainButton?.title}}</span>

</button>
<div class="dropdown" #dropdown>
    <button class="btn btn-md btn-action" (click)="showDropdown()" style="padding: 8px;
    border-radius: 0 4px 4px 0;" [disabled]="!!config?.mainButton?.disabled">
        <div class="eureka-icon">
            <fa-icon [icon]="faCaretUp" *ngIf="!config?.loading"/>
            <div class="loader-icon">
                <fa-icon [icon]="faSpinner" *ngIf="config?.loading"/>
            </div>
        </div>
    </button>

    <div class="dropdown-content dropdown-content-up">
        <ng-container *ngFor="let dropdownButton of config?.buttons">
            <div class="dropdown-link"
                 [style.color]="!dropdownButton.disabled ? dropdownButton.color || 'black' : 'grey'"
                 (click)="!dropdownButton.disabled && dispatchButtonClick(dropdownButton.name)">
                <ng-container *ngIf="dropdownButton.icon">
                    <fa-icon [icon]="dropdownButton.icon" style="margin-right: 8px; "></fa-icon>
                </ng-container>
                {{dropdownButton.title}}
            </div>
        </ng-container>
    </div>
</div>
