<div class="slider-container">
    <span *ngIf="labelStart">
        {{labelStart}}
    </span>
    <input class="slider"
           name="slider"
           id="slider"
           type="range"
           [min]="min || 1"
           [max]="max || 100"
           [value]="initialValue || ''"
           [(ngModel)]="value">
    <span *ngIf="labelEnd">
        {{labelEnd}}
    </span>
</div>
