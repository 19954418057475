import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.css']
})
export class SkeletonComponent {
  @Input() width;
  @Input() height;
  @Input() circle;
  @Input() paddingTop;

  constructor() { }

}
