import { Injectable } from '@angular/core';
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BaseDialogService {
  toggledSubject = new Subject<boolean>();
  toggledValue = false;

  constructor() { }

  dialogToggled(): Observable<boolean> {
    return this.toggledSubject.asObservable();
  }

  toggle() {
    this.toggledValue = !this.toggledValue;
    this.toggledSubject.next(this.toggledValue);
  }
}
