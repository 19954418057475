import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {Observable, Subject, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {User} from "./user";
import {PasswordChange} from "./password-change";
import {AppConfig} from "../config/app-config";

@Injectable()
export class AuthService {
    STORE_TYPE: string = 'wass-app-store';
    AUTH_KEY: string = 'wass-app-authorization';
    _user: User;

    urlPath = '/accounts/';
    _userId: number;

    public userSubject: Subject<User> = new Subject<User>();

    constructor(private http: HttpClient,
                private router: Router,
                @Inject('appConfig') private AppConfig: any) {
    }

    getBaseUrlPath(): string {
        return (AppConfig.settings.apiBase || environment.apiBase) + this.urlPath;
    }

    public userChanged(): Observable<User> {
        return this.userSubject.asObservable();
    }

    public submitSignIn(username: string, password: string, rememberMe: boolean = true): Observable<any> {
        let params = new HttpParams()
            .set('username', username)
            .set('password', password);

        return this.http.post(this.getBaseUrlPath() + 'token/', params)
            .pipe(tap(data => this.setAuthData(data, rememberMe)),
                catchError(err => this.handleError(err)));
    }

    //public signOut(): Observable<any> {
    public signOut(): void {

        this.removeAuthData();
        /*return this.http.get(environment.apiBase + 'auth/logout').pipe(
          tap((res) => this.removeAuthData()),
          tap((res) => this.router.navigate(['/'])),
          catchError(err => this.handleError(err))
        );*/
    }

    public setAuthData(authData: any, isLocalStorage = true): void {
        authData['expiration_date'] = AuthService.tokenExpirationDateStr(authData);
        let dataStr = JSON.stringify(authData);
        if (isLocalStorage) {
            localStorage.setItem(this.STORE_TYPE, 'local');
            localStorage.setItem(this.AUTH_KEY, dataStr);
        } else {
            localStorage.setItem(this.STORE_TYPE, 'session');
            sessionStorage.setItem(this.AUTH_KEY, dataStr);
        }

        this.getAuthUser().subscribe(user => {
            this.userSubject.next(user);
        });
        this.userSubject.next(new User());
    }

    public getAuthData(): any {
        let storage = localStorage.getItem(this.STORE_TYPE);
        let data;
        if (storage === 'session') {
            data = sessionStorage.getItem(this.AUTH_KEY);
        } else {
            data = localStorage.getItem(this.AUTH_KEY);
        }
        if (data && data !== '') {
            return JSON.parse(data);
        } else {
            return null;
        }
    }

    public removeAuthData(): void {
        let storage = localStorage.getItem(this.STORE_TYPE);
        localStorage.removeItem(this.STORE_TYPE);
        if (storage === 'session') {
            sessionStorage.removeItem(this.AUTH_KEY);
        } else {
            localStorage.removeItem(this.AUTH_KEY);
        }
        this._user = null;
        this.userSubject.next(null);
        this.router.navigate(['/signin']).finally();

    }

    public getAccessToken(): string {
        let data = this.getAuthData();
        if (data) {
            return data['access'];
        }
    }

    public getRefreshToken(): string {
        let data = this.getAuthData();
        if (data) {
            return data['refresh'];
        }
    }

    set user(user: User) {
        this._user = user;
    }

    get user(): User {
        return this._user;
    }

    public getAuthUser(fresh: boolean = false): Observable<User> {
        if (!this.isLoggedIn()) {
            return new Observable(o => {
                o.next(null);
                o.complete();
            });
        } else if (this.isLoggedIn() && this._user && !fresh) {
            return new Observable(o => {
                o.next(this._user);
                o.complete();
            });
        }

        this.refreshAuthTokenIfExpired().finally();

        return this.http.get<User>((AppConfig.settings.apiBase || environment.apiBase) + '/accounts/me').pipe(
            tap(resp => this._user = resp),
            tap(resp => this.userSubject.next(resp)),
            catchError(err => this.handleError(err))
        );

    }

    private async refreshAuthTokenIfExpired() {
        let authData = this.getAuthData();

        if (authData['expiration_date']) {
            let expirationDate = new Date(this.getAuthData()['expiration_date']);
            let currentDate = new Date();

            if (expirationDate < currentDate) {
                let authData = await this.http.post(this.getBaseUrlPath() + '/token/refresh/',
                    {refresh: this.getRefreshToken()}).toPromise();
                this.setAuthData(authData, localStorage.getItem(this.STORE_TYPE) === 'local');
            }
        }
    }

    public isLoggedIn(): boolean {
        return !!this.getAccessToken();
    }

    private handleError(error: any) {
        this.removeAuthData();
        return throwError(error);
    }

    private static tokenExpirationDateStr(authData): string {
        if (authData) {
            let secondsLeft = +authData['lifetime'];
            if (secondsLeft) {
                let date = new Date();
                date.setTime(date.getTime() + (secondsLeft * 1000));
                return date.toUTCString();
            }
        }
    }


    set userId(userId: number) {
        this._userId = userId;
    }

    updateUser(user: User) {
        let userData = new FormData();
        Object.keys(user).map((key) => userData.append(key, user[key]));
        if (user.img == undefined) {
            userData.delete('img');
        }

        return this.http.put(`${this.getBaseUrlPath()}/users/${this._userId}/`, userData);
    }

    changePassword(passwordChange: PasswordChange) {
        return this.http.post(`${this.getBaseUrlPath()}/password/change/`, passwordChange);
    }

    resetPassword(email: string) {
        return this.http.post(`${this.getBaseUrlPath()}/password/reset/`, {email: email});
    }

    resetPasswordConfirm(username: string, newPassword: string, token: string) {
        return this.http.post(`${this.getBaseUrlPath()}password/reset/confirm/`,
            {username: username, new_password: newPassword, token: token});
    }

}
