import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AuthModule} from './modules/auth/auth.module';
import {AppConfig} from './modules/config/app-config';
import {CustomCDKModule} from './modules/custom-c-d-k/custom-c-d-k.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AuthModule.forRoot(AppConfig),
    CustomCDKModule,
  ]
})
export class CoreModule { }
