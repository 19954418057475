<div class="w-100 center-sm pagination-container">
  <ul class="text-center d-inline-block" style="margin-bottom: 2px;">
    <li class="page-item page-item">
      <button class="btn page-link"
              [disabled]="disabled || !(currentPage - 1 >= 1)" (click)="changePage(currentPage-1)"
      >
        <fa-icon [icon]="faChevronLeft"></fa-icon>
      </button>
    </li>
    <li class="page-item page-item" *ngIf="currentPage - 1 > 1">
      <button class="btn page-link" (click)="changePage(1)" [disabled]="disabled"> 1</button>
    </li>
    <li class="page-item page-item" *ngIf="currentPage - 1 > 2">
      <button class="btn page-link" [disabled]="true"> ...</button>
    </li>
    <li class="page-item page-item" *ngIf="currentPage - 1 >= 1">
      <button class="btn page-link" href="#" (click)="changePage(currentPage-1)"
              [disabled]="disabled">{{currentPage - 1}}</button>
    </li>
    <li class="page-item page-item  page-item-active">
      <button class="btn page-link" href="#" [disabled]="disabled">{{currentPage}}</button>
    </li>
    <li class="page-item page-item" *ngIf="currentPage +1 <= pagesCount">
      <button class="btn page-link" href="#" (click)="changePage(currentPage+1)"
              [disabled]="disabled">{{currentPage + 1}}</button>
    </li>
    <li class="page-item page-item" *ngIf="currentPage + 1 < pagesCount - 1">
      <button class="btn page-link" [disabled]="true"> ...</button>
    </li>
    <li class="page-item page-item" *ngIf="currentPage + 1 < pagesCount">
      <button class="btn page-link" (click)="changePage(pagesCount)" [disabled]="disabled"> {{pagesCount}}</button>
    </li>
    <li class="page-item page-item">
      <button class="btn page-link"
              [disabled]="disabled || !(currentPage +1 <= pagesCount)" (click)="changePage(currentPage+1)"
      >
        <fa-icon [icon]="faChevronRight"></fa-icon>
      </button>
    </li>
  </ul>
  <div class="page-size-container center-xs end-md">
    <p class="page-size-text">Showing</p>
    <select class="page-size" [(ngModel)]="pageSize"
            (ngModelChange)="pageSizeChangeEmit()" [disabled]="resourcesCount <= 5">
      <ng-container *ngFor="let value of [5, 10, 20, 50, 100], let first = first">
        <option *ngIf="first && value >= resourcesCount">{{resourcesCount}}</option>
        <option [selected]="pageSize === value" *ngIf="value <= resourcesCount">{{value}}</option>
      </ng-container>
    </select>

    <p class="page-size-text">Out of {{resourcesCount}} examples.</p>
  </div>
</div>
