<div class="table-container" style="width :100%; position: relative;min-height: 400px;">
  <table *ngIf="config?.columns" style="width:100%">
    <thead>
    <tr>
      <th *ngFor="let headColumn of config.columns, let first = first"
          [ngClass]="{'sticky-column': first, 'sortable': headColumn.sortable}"
          [style.flexGrow]="headColumn.flexGrow ? headColumn.flexGrow : '1'"
          [style.justifyContent]="headColumn.justifyContent ? headColumn.justifyContent : 'flex-start'"
          (click)="selectSort(headColumn.name)">
        {{headColumn.headerText}}
        <fa-icon class="sort-icon" [icon]="config.sortOrderAscending ? faCaretUp : faCaretDown"
                 *ngIf="config.sortByColumnName === headColumn.name"></fa-icon>
      </th>
      <th class="sticky-column action-column" [style.flexGrow]="'1'" [style.justifyContent]="'center'"
          *ngIf="config.actions"> Actions
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let entry of data" (click)="clickRow($event, entry)">
      <td *ngFor="let headColumn of config.columns, let first = first" [ngSwitch]="headColumn.dataType"
          [ngClass]="{'sticky-column': first}"
          [style.flexGrow]="headColumn.flexGrow ? headColumn.flexGrow : '1'"
          [style.justifyContent]="headColumn.justifyContent ? headColumn.justifyContent : 'flex-start'">
        <!-- TEXT DATA TYPE -->
        <div *ngSwitchDefault>
          {{entry[headColumn.name]}}
        </div>
        <!-- NUMBER DATA TYPE -->
        <div *ngSwitchCase="DataType.NUMBER">
          {{entry[headColumn.name]}}
        </div>
        <!-- GROUP DATA TYPE -->
        <div *ngSwitchCase="DataType.GROUP">
          <p>
            {{entry[headColumn.name]}}
          </p>
          <p *ngIf="headColumn.data?.groupName && headColumn.data?.text"
             style="font-size: 12px; line-height: 16px; color: #616E7C;">
            <span style="">This {{config.type}} belongs to </span>
            <a class="group-link" style="color: #1E86FF; cursor: pointer;"
                  (click)="selectGroupList(entry)"> {{headColumn.data.text | replace : '\{\{\}\}' : entry[headColumn.data.groupName]?.length ? entry[headColumn.data.groupName]?.length : '0' }}
            </a>
          </p>
        </div>
        <!-- BUTTON DATA TYPE -->
        <div *ngSwitchCase="DataType.BUTTON">
          <button class="button btn">
            <span *ngIf="headColumn.data?.text">
                {{headColumn.data.text}}
            </span>
          </button>
        </div>
      </td>
      <td [style.flexGrow]="'1'" [style.justifyContent]="'center'" class="sticky-column action-column">
        <!-- MENU DATA TYPE -->
        <div *ngIf="config.actions">
          <app-dropdown-menu
            [buttons]="config.actions"
            (detailsClick)="openEntry(entry)"
            (deleteClick)="deleteEntry(entry)"
            (buttonClick)="menuClickHandle($event, entry)">
          </app-dropdown-menu>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
  <div *ngIf="data.length === 0" class="center-sm no-data-container">
    <img src="../../../../../../../assets/images/empty-table.png" style="width: 100px;">
    <p style="font-size: 18px; color: var(--main-color-dark);"> There is no result to show</p>
    <p>You don't have any resources of this type yet</p>
  </div>
</div>

<app-confirm-dialog
  #confirmDeleteDialog
  [config]="confirmDeleteDialogConfig"></app-confirm-dialog>
