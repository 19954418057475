import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-list-tabs',
  templateUrl: './list-tabs.component.html',
  styleUrls: ['./list-tabs.component.css']
})
export class ListTabsComponent implements OnInit {
  @Input() tabs: {name: string, title: string }[];
  @Output() tabSelect = new EventEmitter<any>();

  selectedTab: any;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tabs) {
      this.selectedTab = this.tabs.length > 0 ? this.tabs[0] : null;
    }
  }

  selectTab(tab: { name: string; title: string }) {
    this.selectedTab = tab;
    this.tabSelect.emit(tab);
  }
}
