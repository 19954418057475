import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseDialogComponent} from '../../components/base-dialog/base-dialog.component';
import {BaseDialogService} from '../../components/base-dialog/base-dialog.service';

export class ConfirmDialogConfig {
    title: string;
    text: string;
    buttons: {
        label?: string, name: string, class: string
    }[];
}

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent extends BaseDialogComponent implements OnInit {
    @Input() config: ConfirmDialogConfig;
    @Output() buttonClick = new EventEmitter<string>();

    constructor(baseDialogService: BaseDialogService) {
        super(baseDialogService);
    }

    ngOnInit(): void {
    }

    buttonClickedEmit(name: string): void {
        this.buttonClick.emit(name);
    }
}
