import {Component} from '@angular/core';
import {BaseDialogService} from './core/modules/custom-c-d-k/components/base-dialog/base-dialog.service';
import {AuthService} from './core/modules/auth/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../environments/environment';
import {AppConfig} from './core/modules/config/app-config';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {NetworkStatusService} from '@app/services/network-status.service';
import {Snackbar} from './core/modules/custom-c-d-k/modules/snackbar/snackbar';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    defaultLanguage: string = environment.defaultLanguage;
    title = 'Terrymapper';
    sidebarCollapsed = true;
    contentBlurred = false;
    favIcon: HTMLLinkElement = document.querySelector('#appIcon');
    isSignedIn: boolean;
    unsignedFunctions: boolean;

    constructor(private baseDialogService: BaseDialogService,
                private authService: AuthService,
                public translate: TranslateService,
                private titleService: Title,
                private activatedRoute: ActivatedRoute,
                private networkStatusService: NetworkStatusService,
                private snackbar: Snackbar) {
        this.baseDialogService.dialogToggled().subscribe(active => {
            this.contentBlurred = active;
        });

        this.unsignedFunctions = (/^(.)*sites\/shared\/(.)*$/.test(window.location.href));
        this.isSignedIn = this.authService.isLoggedIn();

        const availableLanguages = ['en', 'de'];
        translate.addLangs(availableLanguages);

        if (AppConfig.settings.defaultLang) {
            translate.setDefaultLang(AppConfig.settings.defaultLang);
        } else {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < navigator.languages.length; i++) {
                const langCode = navigator.languages[i].split('-')[0];

                if (availableLanguages.includes(langCode)) {
                    translate.setDefaultLang(langCode);
                    break;
                }
            }

            if (!translate.getDefaultLang()) {
                translate.setDefaultLang(this.defaultLanguage);
            }
        }

        if (AppConfig.settings.title) {
            this.titleService.setTitle(AppConfig.settings.title);
        }

        if (AppConfig.settings.favicon) {
            this.favIcon.href = AppConfig.settings.favicon;
        }

        Object.keys(AppConfig.settings.stylingVariables).forEach(variable => {
            document.documentElement.style.setProperty(
                variable,
                AppConfig.settings.stylingVariables[variable]
            );
        });

        this.authService.userChanged().subscribe(user => {
            this.isSignedIn = this.authService.isLoggedIn();

            if (this.isSignedIn && user && user.language_preference) {
                translate.use(user.language_preference);
            }
        });

        this.networkStatusService.handleAppConnectivityChanges(this.snackbar, this.translate);
    }

    sidebarCollapseToggled(isCollapsed: boolean): void {
        this.sidebarCollapsed = isCollapsed;
    }

    switchLang(lang: string): void {
        this.translate.use(lang);
    }
}
