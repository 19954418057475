import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {AuthService} from '../../../core/modules/auth/auth.service';
import {Subscription} from 'rxjs';
import {User} from '../../../core/modules/auth/user';
import * as _ from 'lodash';
import {environment} from '../../../../environments/environment';
import {AppConfig} from '../../../core/modules/config/app-config';

/**
 * Component used to build user-profile dropdown in navbar.
 */
@Component({
  selector: 'app-mini-profile',
  templateUrl: './mini-profile.component.html',
  styleUrls: ['./mini-profile.component.css']
})
export class MiniProfileComponent implements OnInit, AfterViewInit, OnDestroy {
  /**
   * Dropdown nativeElement reference, used in binding outside document click to closing the dropdown.
   */
  @ViewChild('dropdown') dropdown;
  /**
   * Dropdown active flag, used to display dropdown content.
   */
  dropdownActive = false;
  /**
   * Mechanism for binding outside click callback function scope to component scope.
   * @private
   */
  private dropdownOutsideClickBound: any;

  /**
   * User data change subscription, used to refresh user data on change from auth service.
   * @private
   */
  private userSubscription: Subscription;

  /**
   * Current user data object retrieved from auth service.
   */
  user: User;

  /**
   * Current user image, separated from user object to show placeholder values if needed.
   */
  userDisplayImg: string;

  /**
   * Base api url location.
   */
  baseApiUrl: string = AppConfig.settings.apiBase || environment.apiBase;

  /**
   * Component constructor.
   *
   * @param document global DOCUMENT object
   * @param authService authnetication service
   */
  constructor(@Inject(DOCUMENT) private document: Document,
              private authService: AuthService) {
  }

  /** Initialize authorization service get auth user callbacks, and inject DOCUMENT object.
   */
  ngOnInit(): void {
    this.authService.getAuthUser().subscribe((user) => {
      this.setUserDataFromApi(user);
    });

    this.userSubscription = this.authService.userChanged()
      .subscribe(user => {
        this.setUserDataFromApi(user);
      });
  }

  /**
   * Bind dropdown callback function scope to component scope after view is initialized.
   */
  ngAfterViewInit(): void {
    this.dropdownOutsideClickBound = this.dropdownOutsideClick.bind(this);
  }

  /**
   * On destroy clear custom user data change subscription.
   */
  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  /**
   * Close dropdown on outside of user-profile click.
   * @param target
   */
  dropdownOutsideClick({target}): void {
    const appMenuItem = this.document.querySelector('.mini-profile');
    if (appMenuItem && !this.dropdown.nativeElement.parentElement.contains(target)) {
      this.toggleDropdown();
    }
  }

  /**
   * Toggle dropdown active/inactive state and add or remove global click event listener for outside dropdown click.
   */
  toggleDropdown(): void {
    this.dropdownActive = !this.dropdownActive;

    if (this.dropdownActive) {
      this.document.body.addEventListener('click', this.dropdownOutsideClickBound);
    } else {
      this.document.body.removeEventListener('click', this.dropdownOutsideClickBound);
    }
  }

  /**
   * Set user data and user image from the API endpoint.
   *
   * @param user
   */
  setUserDataFromApi(user: User): void {
    if (user) {
      this.user = user;
      this.setDisplayImg();
    } else {
      delete this.user;
    }
  }

  /**
   * Sign out user using auth service.
   */
  signOut(): void {
    this.authService.signOut();
  }

  /**
   * Set user profile img to placeholder.
   */
  setPlaceholderImg(): void {
    this.userDisplayImg = 'assets/images/placeholder-profile.png';
  }

  /**
   * Method that sets display image src value.
   */
  setDisplayImg(): void {
    if (this.user.img) {
      this.userDisplayImg = this.user.img.includes(this.baseApiUrl) ? this.user.img : `${this.baseApiUrl}/${this.user.img}`;
    } else if (this.user.first_name && this.user.first_name.length >= 1 && this.user.last_name && this.user.last_name.length >= 1) {
      if (AppConfig.settings.customProfileImages) {
        this.userDisplayImg = AppConfig.settings.customProfileImages + this.user.first_name[0] + '_' + this.user.last_name[0] + '.jpeg';
      } else {
        this.userDisplayImg = 'assets/profile_images/' + this.user.first_name[0] + '_' + this.user.last_name[0] + '.jpeg';
      }
    } else {
      this.userDisplayImg = 'assets/images/placeholder-profile.png';
    }
  }
}
