import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ListTableComponent} from './list-table/list-table.component';
import {ReplacePipe} from "../../../../../shared/pipes/replace.pipe";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {DropdownMenuComponent} from './list-table/subcomponents/dropdown-menu/dropdown-menu.component';
import {ListHeaderComponent} from "./list-header/list-header.component";
import {ListEmptyComponent} from "./list-empty/list-empty.component";
import {SpinnerModule} from "../spinner/spinner.module";
import {ListTabsComponent} from './list-tabs/list-tabs.component';
import {FormsModule} from "@angular/forms";
import { PaginationComponent } from './pagination/pagination.component';
import {Snackbar} from "../snackbar/snackbar";
import {ResourceModule} from "../resource/resource.module";
import {ConfirmDialogModule} from "../confirm-dialog/confirm-dialog.module";
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [DropdownMenuComponent, ListEmptyComponent, ListHeaderComponent, ListTableComponent, ReplacePipe, ListTabsComponent, PaginationComponent],
  providers: [Snackbar],
  imports: [
    FormsModule,
    CommonModule,
    FontAwesomeModule,
    SpinnerModule,
    ResourceModule,
    ConfirmDialogModule
  ],
    exports: [ListEmptyComponent, ListHeaderComponent, ListTableComponent, ListTabsComponent, PaginationComponent, DropdownMenuComponent]
})
export class ListModule {
}
