<div #rightSidebar class="right-sidebar-container"
     (click)="closeSidebar($event, false)">
  <div class="right-sidebar">
    <div class="right-sidebar-content">
      <!-- SIDEBAR HEADER -->
      <div class="header">
        <p class="title">Add new {{config?.type}}</p>
        <fa-icon class="close-icon" [icon]="closeIcon" [size]="'sm'" (click)="closeSidebar($event, true)"></fa-icon>
      </div>
      <!-- SIDEBAR HEADER END -->
      <!-- SIDEBAR MAIN CONTENT -->
      <main>
        <app-resource-form #resourceForm
                           [config]="config?.resourceFormConfig"
                           (valueChanges)="setResource($event)">
        </app-resource-form>
      </main>
      <!-- SIDEBAR MAIN CONTENT END -->
      <!-- SIDEBAR FOOTER -->
      <div class="cf footer" style="margin-top: 50px;">
        <button class="btn btn-md btn-cancel" (click)="closeSidebar($event, true)">Cancel</button>
        <button class="btn btn-md btn-action" (click)="save(false)">Save and add another</button>
        <button class="btn btn-md btn-action" (click)="save(true)">Save</button>
      </div>
    </div>
    <!-- SIDEBAR FOOTER END -->
  </div>
  <div class="overlay"></div>
</div>
