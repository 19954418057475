<div style=" height: 60px; width: 100%;"  *ngIf="!filenames || multiple">
    <div class="upload-file-container parent" [ngClass]="{'disabled': disabled}"
         appDragAndDrop
         (click)="fileInput.click()"
         (onFileDropped)="uploadFile($event)">
        <div class="child_1 center-sm">
            <fa-icon [icon]="fileIcon"></fa-icon>
            <p class="file-drag-desc">
                {{placeholder || 'Drop your file here or click to upload'}}
              </p>
        </div>
    </div>
</div>

<ng-container>
    <div class="chip" *ngFor="let filename of filenames">
        <span style="margin-right: 10px;">{{filename}}</span>
        <fa-icon style="color: black; cursor: pointer;" [icon]="closeIcon" [size]="'xs'" style="cursor: pointer; position: absolute; left: 135px;"
                 (click)="deleteFile()"></fa-icon>
    </div>
</ng-container>

<input style="display: none;" type="file" #fileInput (change)="uploadFile($event.target['files'])" [multiple]="multiple" [disabled]="disabled">
